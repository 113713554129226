converse-add-muc-modal {
    .add-chatroom {
        converse-autocomplete {
            .suggestion-box__results--below {
                height: 10em;
                overflow: auto;
            }

            .suggestion-box ul li {
                display: block;
            }
        }
    }
}
