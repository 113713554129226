.conversejs {
    .chatbox {
        .chat-head {
            display: flex;
            flex-direction: row;
            color: #ffffff;
            font-size: 100%;
            margin: 0;
            padding: 0;
            position: relative;

            &.chat-head-chatbox {
                background-color: var(--chat-head-color);
                border-bottom: var(--chat-head-border-bottom);
            }

            .avatar {
                margin-right: 0.5em;
            }

            .show-msg-author-modal {
                color: var(--chat-head-text-color) !important;
            }

            .chat-head__desc {
                color: var(--chat-head-color-lighten-50-percent);
                font-size: var(--font-size-small);
                margin: 0;
                overflow: hidden;
                padding: 0.5rem 1rem 0.5rem 1rem;
                text-overflow: ellipsis;
                width: 100%;
            }

            .chatbox-title {
                padding: 0.75rem 1rem 0 1rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            .chatbox-title--no-desc {
                padding: 0.75rem 1rem;
            }

            .chatbox-title--row {
                display: flex;
                flex-direction: row;
                overflow: hidden;
                width: 100%;
            }

            .chatbox-title__text {
                color: var(--chat-head-text-color);;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .chatbox-title__buttons {
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: nowrap;
                padding: 0;
            }

            .chatbox-btn {
                color: white;
                &:active {
                    position: relative;
                    top: 1px;
                }
            }

            converse-dropdown {
                .dropdown-menu {
                    converse-icon {
                        svg {
                            fill: var(--chat-color);
                        }
                    }
                }
            }


            .chatbox-btn {
                converse-icon {
                    svg {
                        fill: var(--chat-head-fg-color);
                    }
                }
            }
        }
    }
}
