.conversejs {
    converse-chats {
        &.converse-chatboxes {
            z-index: 1031; // One more than bootstrap navbar
            position: fixed;
            bottom: 0;
            right: 0;
        }
        &.converse-overlayed {
            height: 3em;
            > .row {
                flex-direction: row-reverse;
            }
        }

        &.converse-fullscreen,
        &.converse-mobile {
            flex-wrap: nowrap;
            width: 100vw;
        }
        &.converse-embedded {
            box-sizing: border-box;
            *, *:before, *:after {
                box-sizing: border-box;
            }
            bottom: auto;
            height: 100%; // When embedded, it fills the containing element
            position: relative;
            right: auto;
            width: 100%;

            &.converse-chatboxes {
                z-index: 1031; // One more than bootstrap navbar
                position: inherit;
                flex-wrap: nowrap;
                bottom: auto;
                height: 100%;
                width: 100%;
            }
        }
    }
}
