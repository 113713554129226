@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "shared/styles/_mixins.scss";

.conversejs {
    @import "bootstrap/scss/card";

    converse-message-unfurl {
        .card--unfurl {
            background-color: var(--chat-content-background-color);
            .card-body {
                padding: 0.75rem;
                .vcard-title {
                    font-size: 90%;
                }
            }
            .card-text {
                converse-rich-text {
                    font-size: 80%;
                }
            }
            .card-img-top {
                margin-top: 0.75rem;
            }
        }
    }

    converse-chats {
        &.converse-embedded,
        &.converse-fullscreen {
            .message {
                .card--unfurl {
                    margin: 1em 0;
                    @include media-breakpoint-down(sm) {
                        max-width: 95%;
                    }
                    @include media-breakpoint-up(md) {
                        max-width: 75%;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 60%;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 30%;
                    }
                }
            }
        }

        &.converse-overlayed,
        &.converse-mobile {
            .message {
                .card--unfurl {
                    margin: 1em 0;
                    max-width: 95%;
                }
            }
        }
    }
}
