@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

#conversejs-bg {
    .subdued {
        opacity: 0.35;
    }
    .converse-brand {
        display: flex;
        justify-content: space-between;
        margin-top: 15vh;
        animation-name: fadein;
        animation-fill-mode: forwards;
        animation-duration: 5s;
        animation-timing-function: ease;
    }
    .converse-brand__text {
        color: #ffffff;
        font-family: var(--branding-font);
        font-weight: normal;
        text-align: center;
        font-size: 140%;
        margin-left: 0.2em;
        .byline {
            margin: 0;
            font-family: var(--heading-font);
            font-size: 0.3em;
            opacity: 0.55;
            margin-bottom: 2em;
            margin-left: -2.7em;
            word-spacing: 5px;
        }
    }

    @media screen and (max-width: $mobile-portrait-length) {
        .converse-brand {
            display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .converse-brand {
            display: none;
        }
    }
}

.converse-fullscreen {
    #conversejs-bg {
        .converse-brand__padding {
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                @include make-col(4);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(3);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(2);
            }
            padding: 0;
        }

        .converse-brand__heading {
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                font-size: 4em;
                @include make-col(8);
            }
            @include media-breakpoint-up(lg) {
                font-size: 5em;
                @include make-col(9);
            }
            @include media-breakpoint-up(xl) {
                font-size: 6em;
                @include make-col(10);
            }
            padding: 0;
            display: flex;
            justify-content: center;
            margin: auto;
            svg {
                margin-top: 0.3em;
            }
        }
    }
}

.converse-overlayed {
    #conversejs-bg {
        .converse-brand__padding {
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                @include make-col(2);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(1);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(1);
            }
            padding: 0;
        }

        .converse-brand__heading {
            @include make-col-ready();
            @include media-breakpoint-up(md) {
                font-size: 4em;
                @include make-col(8);
            }
            @include media-breakpoint-up(lg) {
                font-size: 5em;
                @include make-col(10);
            }
            @include media-breakpoint-up(xl) {
                font-size: 6em;
                @include make-col(10);
            }
            padding: 0;
            display: flex;
            justify-content: center;
            margin: auto;
            svg {
                margin-top: 0.3em;
            }
        }
    }
}
