.conversejs {
    .chat-status {
        vertical-align: middle;
        margin-right: 0;
        border-radius: 50%;
        font-size: 1em;

        &.chat-status--avatar {
          font-size: 0.6rem;
          margin-left: -0.7em;
          margin-bottom: -1.9em;
          border-radius: 50%;
        }
    }
    .chat-status--offline {
        margin-right: 0.8em;
    }
    .chat-status--online {
        color: var(--chat-status-online);
        svg {
            fill: var(--chat-status-online);
        }
    }
    .chat-status--busy {
        color: var(--chat-status-busy);
        svg {
            fill: var(--chat-status-busy);
        }
    }
    .chat-status--away {
        color: var(--chat-status-away);
        svg {
            fill: var(--chat-status-away);
        }
    }
    .chat-status--offline {
        display: none;
    }
}
