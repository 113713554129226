converse-chat-message {
    .message {
        &.chat-msg--retracted {
            .chat-msg__message {
                color: var(--subdued-color);
            }
        }

    }
}
